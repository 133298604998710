<template>
  <b-row>
    <b-col
      size="12"
      md="8"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Başlık"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Başlık"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="itemData.title"
                  placeholder="Başlık"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              label="Bağlantı"
              label-for="url"
            >
              <validation-provider
                #default="{ errors }"
                name="Bağlantı"
                rules="url"
              >
                <b-form-input
                  id="url"
                  v-model="itemData.url"
                  placeholder="Bağlantı"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Sıra No"
              label-for="ordering"
            >
              <validation-provider
                #default="{ errors }"
                name="Sıra No"
                rules="required"
              >
                <b-form-input
                  id="ordering"
                  v-model="itemData.ordering"
                  placeholder="Sıra No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-button
        variant="primary"
        :disabled="itemData.submitStatus"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
    <b-col
      size="12"
      md="4"
    >
      <image-card />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BRow, BButton,
} from 'bootstrap-vue'
import ImageCard from '@/views/Admin/References/Image.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  name: 'ItemForm',
  components: {
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ImageCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      required,
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['references/dataItem']
    },
  },
}
</script>
